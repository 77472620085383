import React, { useState, useCallback } from 'react'
import Gallery from "react-photo-gallery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ProductDesignPageProductGallery = ({ slug, productName, productGallery}) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const photos = productGallery.map(image => {
        return {
            src: process.env.GATSBY_BACKEND_URL + image.url,
            width: image.width,
            height: image.height
        }
    })

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
      <section className="bg-white">
        <div className="container mx-auto px-6 py-20">
          <div id={slug}></div>
          <h1 className="font-garamond text-3xl">{productName}</h1>
          <div className="block w-full border-t-2 pt-5">
            <Gallery
              photos={photos}
              direction={"row"}
              onClick={openLightbox}
              targetRowHeight={800}
              margin={8}
            />

            {viewerIsOpen && (
              <Lightbox
                mainSrc={photos[currentImage].src}
                nextSrc={photos[(currentImage + 1) % photos.length].src}
                prevSrc={
                  photos[(currentImage + photos.length - 1) % photos.length].src
                }
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() =>
                  setCurrentImage(
                    (currentImage + photos.length - 1) % photos.length
                  )
                }
                onMoveNextRequest={() =>
                  setCurrentImage((currentImage + 1) % photos.length)
                }
              />
            )}
          </div>
        </div>
      </section>
    );
}

export default ProductDesignPageProductGallery