import React from 'react'
import { useQuery } from '@apollo/client'
import Layout from '../components/layout'
import ProductDesignHeader from '../components/product-design-header'
import ProductDesignPageProductGallery from '../components/product-design-page-product-gallery'
import ProductDesignPageProducts from '../components/product-design-page-products'
import FullscreenLoadingSpinner from '../components/fullscreen-loading-spinner'
import { GET_PRODUCT_DESIGN_PAGE_DATA } from '../queries/queries'


const ProductDesignPage = () => {

    const { data, loading, error } = useQuery(GET_PRODUCT_DESIGN_PAGE_DATA)

    const productDesignGalleryMapper = () => {
        return data.designs.map(design => {
            return (
                <ProductDesignPageProductGallery 
                    key={design.id}
                    slug={design.product_design_link_slug}
                    productName={design.product_design_name}
                    productGallery={design.product_design_gallery}
                />
            )
        })
    }

    return (
        <>
            <FullscreenLoadingSpinner loading={loading} />
            {!loading &&
            <Layout>
                {/* <ProductDesignHeader 
                    pageHeaderText={data.productDesign.page_heading}
                    pageHeaderImage={data.productDesign.page_header_image.url}
                /> */}
                {/* <ProductDesignPageProducts
                    productData={data.designs}
                /> */}
                {productDesignGalleryMapper()}
            </Layout>}
        </>
    )
}

export default ProductDesignPage